<template>
  <v-card 
    class="io-folder" 
    @click="handleClicked();"
    outlined
    :class="{ 'active-folder' : activeFolderId == folder.id }"
    :ripple="false"
  >
    <div class="io-folder-icon">
      <v-icon class="ml-4">{{icon}}</v-icon>
    </div>
    <v-card-title class="io-folder-text">
      {{ folderName }}
      <v-spacer></v-spacer>
    </v-card-title>
    <div class="io-folder-menu">
      <v-menu
        offset-y
        class="mt-2"
        dense
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            x-small
            text
          >
            <v-icon size="20">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="adding = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-arrow-down-left</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('move')}}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="renaming = true; newName = folder.name;"
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('rename')}}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="deleting = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('delete')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <play-clip
      v-if="playing"
      :visible="playing"
      :time_id="folder.time_id"
      :clip_id="folder.clip_id"
      @close="closeClip()"
    ></play-clip>
    <v-dialog @close="showingFile = false; closeView()" @click:outside="showingFile = false; closeView()" scrollable :value="showingFile" max-width="1000">
      <open-text-file
        v-if="showingFile"
        :text_file_id="folder.text_file_id"
        @close="showingFile = false; closeView()"
      />
    </v-dialog>
    <folder-map
      v-if="showingMap"
      @close="closeMap()"
      :map_id="folder.map_id"
    ></folder-map>
    <ChooseFolder
      @input="chosenFolder = $event"
      @closed="adding = false"
      @chosen="moveToFolder($event)"
      :text="$t('library.move_to_folder')"
      :visible="adding"
      :disabledParentId="folder.id"
    />
    <RenameDialog
      v-model="newName"
      :visible="renaming"
      @close="renaming = false"
      @rename="rename()"
    />
    <ConfirmationDialog
      :show="deleting"
      :subText="$t('library.confirm_delete', [folder.name])"
      btnColor="red"
      :btnText="$t('delete')"
      :text="$t('library.delete_folder')"
      @decline="deleting = false"
      @accept="acceptDeleting()"
    />
  </v-card>
</template>

<script>
import ChooseFolder from '@/components/folder/ChooseFolder'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import RenameDialog from '@/components/RenameDialog'
import FolderMap from '@/components/maps/FolderMap'
import OpenTextFile from '@/components/textfile/OpenTextFile'
import PlayClip from '../video/PlayClip.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: ['folder'],
  components: { OpenTextFile, FolderMap, ChooseFolder, ConfirmationDialog, RenameDialog, PlayClip },
  data: () => ({
    adding: false,
    deleting: false,
    renaming: false,
    newName: '',
    playing: false,
    showingMap: false,
    showingFile: false
  }),
  created() {
    if(this.folder.id == this.$route.query?.id) {
      this.handleClicked()
    }
  },
  methods: {
    ...mapActions('folder', [
      'updateFolder',
      'deleteFolder',
      'openFolder',
      'getFolders'
    ]),
    ...mapActions('noti', [
      'success',
      'error',
      'info'
    ]),
    ...mapActions('comment', [
      'resetComment'
    ]),
    ...mapMutations('folder', [
      'SET_ACTIVE_FOLDER_ID'
    ]),
    handleClicked() {
      switch(this.folder.type) {
        case 'folder':
          this.openFolder(this.folder.id)
          break;
        case 'time':
          this.playing = true;
          break;
        case 'clip':
          this.playing = true;
          break;
        case 'filter':
          this.$router.replace(`/filter/library/${this.folder.filter_id}`)
          break;
        case 'map':
          this.showingMap = true
          break;
        case 'text_file':
          this.showingFile = true
          break;
      }

      if(this.folder.type !== 'filter') {
        this.$router.replace({
          query: {
            id: this.folder.id
          }
        })
    } 
    },
    closeClip() {
      this.resetComment
      this.playing = false
      this.$router.replace({
        query: {
          id: this.folder.parent || 'root'
        }
      })
    },
    closeMap() {
      this.showingMap = false
      this.$router.replace({
        query: {
          id: this.folder.parent || 'root'
        }
      })
    },
    closeView() {
      this.$router.replace({
        query: {
          id: this.folder.parent || 'root'
        }
      })
    },
    moveToFolder(parent) {
      this.adding = false
      
      let updatedFolder = {
        ...this.folder,
        parent: parent == 'root' ? undefined : parent.id
      }
      this.updateFolder(updatedFolder)
        .then(() => {
          this.success(this.$t('library.folder_moved') + ' :)')
        })
        .catch(e => {
          this.error(e)
        })
    },
    acceptDeleting() {
      this.deleteFolder(this.folder.id)
        .then(() => {
          this.info(this.$t('library.folder_deleted'))
        })
        .catch(e => {
          this.error(e)
        })
    },
    rename() {
      if(!this.newName || this.newName == this.folder.name) {
        this.renaming = false
        return
      }

      this.updateFolder({
        ...this.folder,
        name: this.newName
      })
      .then(() => this.info(this.$t('renamed') + '!'))
      .catch(e => this.error(e))
      .finally(() => this.renaming = false)
    }
  },
  computed: {
    ...mapGetters('folder', [
      'activeFolderId',
      'openedFolder',
      'folders'
    ]),
    folderName() {
      return this.folder.name
      // if(this.folder.name.length < 20) return this.folder.name
      // return this.folder.name.substring(0, 20) + '...'
    },
    icon() {
      switch(this.folder.type) {
        case 'clip':
          return 'mdi-video-outline'
        case 'time':
          return 'mdi-clock-outline'
        case 'filter':
          return 'mdi-filter-outline'
        case 'map':
          return 'mdi-dots-hexagon'
        case 'text_file':
          return 'mdi-file-document-outline'
        default:
          return 'mdi-folder'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .io-folder {
    border-radius: 20px;
    display: flex !important;
    flex-direction: row;
    flex-basis: 10px 1 10px;
    white-space: nowrap;
    transition-duration: 0.2s;
    
    &-icon {
      width: 35px;
      min-width: 35px;
      display: flex;
      justify-content: center;
    }
    
    &-text {
      overflow-x: hidden;
      font-size: 20px;
      flex-grow: 1;
      white-space: nowrap;
    }

    &-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .active-folder {
    color: blue !important;
  }
</style>