<template>
  <v-card style="overflow-y: scroll;" max-width="1000" class="mx-auto" :class="cardHeight">
    <v-btn v-if="!presentation" @click="$emit('close')" medium fab style="position: absolute; top: 10px; right: 5px;">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-btn v-if="!presentation" @click="editing = !editing" small color="primary" fab style="position: absolute; top: 10px; right: 70px;">
      <v-icon>mdi-pencil-outline</v-icon>
    </v-btn>
    <div v-html="html" v-if="!editing" class="pa-10"></div>
    <div v-else>
      <create-text-file
        :editing="true"
        @close="editing = false"
        v-model="file"
        @update="html = $event.text_file; editing = false;"
      ></create-text-file>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import CreateTextFile from '@/components/textfile/CreateTextFile.vue'

export default {
  props: ['text_file_id', 'presentation'],
  components: { CreateTextFile },
  shortcuts: {
    escape: function() {
      this.$emit('close')
    }
  },
  data: () => ({
    html: '',
    file: {},
    editing: false
  }),
  created() {
    this.getFile({id: this.text_file_id})
      .then(e => {
        this.html = e.text_file
        this.file['title'] = e.title
        this.file['text'] = e.text_file
        this.file['id'] = e.id
      })
      .catch(e => {
        this.error(e)
      })
  },
  methods: {
    ...mapActions('noti', ['error']),
    ...mapActions('text_file', ['getFile'])
  },
  computed: {
    cardHeight() {
      if(this.presentation) return this.$vuetify.breakpoint.mobile ? 'card-mobile': 'card-prese';
      return 'card'
    }
  },
}
</script>

<style scoped>
.card {
  height: 60vh;
}
.card-prese {
  height: 75vh;
}
@media (orientation: landscape){
  .card-mobile {
    height: 90%;
  }
}
@media (orientation: portrait) and (max-width: 450px) {
  .card-mobile {
    height: 250px;
  }
}
@media (orientation: portrait) and (min-width: 500px) {
  .card-mobile {
    height: 350px;
  }
}
</style>