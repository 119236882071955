<template>
  <div>
    <div class="mx-auto" style="display: flex; justify-content: center;" :id="konvaId" :style="convaContStyle">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
      ></v-progress-linear>
    </div>
    <div v-if="!hideButtons" style="margin-top: 20px;" >
      <v-btn
        v-for="pointcolor in colors"
        :key="'colorchoose' + pointcolor"
        :color="color == pointcolor ? 'grey lighten-1' : ''"
        small
        class="mr-1"
        @click="color = pointcolor"
      >
          <div class="ball" :style="`background-color: ${pointcolor}`"></div>
      </v-btn>
      <v-btn
        @click="color = 'erase'"
        :color="color == 'erase' ? 'grey lighten-1' : ''"
        style="height: 28px; width: 50px;"
      >
        <v-icon small class="pa-0">mdi-eraser</v-icon>
      </v-btn>
    </div>
    <div v-if="!card && !hide_showable_shapes" class="mb-2" :style="`display: flex; justify-content: start; margin-left: ${info ? '-50px' : ''}`">
      <v-btn x-small class="ml-1 mr-1 togglebtn" :color="arrowsVisible ? 'primary' : ''" @click.stop="toggleArrows">
        <v-icon>mdi-arrow-top-right-thin</v-icon>
      </v-btn>
      <v-btn x-small class="togglebtn" :color="circlesVisible ? 'primary' : ''" @click.stop="toggleCircles">
        <v-icon>mdi-circle-medium</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Konva from 'konva';

export default {
  props: ['hide_showable_shapes', 'card', 'info', 'offsetY', 'offsetX', 'colorOverride', 'buttonIndex', 'loading', 'maxHeight', 'maxWidth', 'base', 'value', 'show', 'hideButtons', 'url', 'disableClicks'],
  data: () => ({
    color: 'red',
    colors: [
      'red',
      'lightgreen',
      'yellow',
      'grey'
    ],
    baseImage: {
      height: null,
      width: null,
      x: null,
      y: null
    },
    object: null,
    layer: null,
    imageObject: null,
    idCounter: 0,
    konvaId: Math.random().toString(),
    startx: 0,
    starty: 0,
    stage: null,
    arrowsVisible: true,
    circlesVisible: true,
  }),
  created() {
    if(this.buttonIndex) this.color = this.colors[this.buttonIndex]

    this.$nextTick(() => {
      const con = this.maxHeight || document.querySelector('#card-container').clientHeight - 110
      const colcon = this.maxWidth || document.querySelector('#col-container').clientWidth
      const width = Math.min(con, colcon)
      const height = Math.min(con, colcon)

      const stage = new Konva.Stage({
        container: this.konvaId,
        width,
        height
      });
  
      var layer = new Konva.Layer();
      stage.add(layer);

      layer.on('pointerdown', (e) => {
        this.handlemouseDown(e, width, layer)
      })

      layer.on('mousemove', (e) => {
        this.handleMouseMove(e)
      })

      layer.on('pointerup', (e) => {
        this.handleMouseUp(e)
      })

      var imageObj = new Image();

      var self = this
      var image;
      var data;
      imageObj.onload = function() {
        const w = imageObj.width
        const h = imageObj.height
        
        data = self.getImageMeasures(w, h, width, height)

        image = new Konva.Image({
          ...data,
          image: imageObj
        });

        layer.add(image);
        self.drawPoints(layer)
        self.drawArrows(layer)

        self.imageObject = image
      };

      imageObj.src = this.url || this.base?.url

      layer.on('mouseover', function () {
        document.body.style.cursor = 'crosshair';
      });

      layer.on('mouseout', function () {
        document.body.style.cursor = 'default';
      });

      this.layer = layer
      this.stage = stage
    })
  },
  computed: {
    convaContStyle() {
      if(this.maxHeight || this.maxWidth) {
        return `max-width: ${this.maxWidth}px; max-height: ${this.maxHeight}px`
      }

      return ''
    }
  },
  methods: {
    downloadURI(uri, name) {
      var link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadMap() {
      const dataUrl = this.stage.toDataURL({ pixelRatio: 3 });
      this.downloadURI(dataUrl, 'map.png')
    },
    getId() {
      return Math.random().toString()
    },
    getNewId() {
      let max = 0
      this.value.forEach(p => {
        if(Number(p.id) > max) max = Number(p.id)
      })

      return max + 1
    },
    handlemouseDown(e) {
      if(this.disableClicks) return

      if(this.color == 'erase') return

      this.object = {
        x: e.evt.layerX,
        y: e.evt.layerY,
        end_x: null,
        end_y: null,
        color: this.colorOverride || this.color,
        style: 'ball',
        id: this.getNewId().toString(),
      }

      this.startx = e.evt.layerX
      this.starty = e.evt.layerY

      // let ne = this.value.concat(newpoint)
      //baseImage x, y, width and height right right
      // 

      // ne = this.value.concat({
      //   x: this.baseImage.x + this.baseImage.width,
      //   y: this.baseImage.y + this.baseImage.height,
      //   color: this.colorOverride || this.color,
      //   style: 'ball',
      //   id: this.getNewId().toString()
      // })

      this.idCounter++

      // this.$emit('input', ne)

      // var circle = new Konva.Circle({
      //   x: e.evt.layerX,
      //   y: e.evt.layerY,
      //   radius: width / 140,
      //   fill: this.color,
      //   stroke: 'black',
      //   strokeWidth: 1,
      // });

      // circle.on('click', (e) => {
      //   if(this.color != 'erase') return
      //   console.log(e)
      //   e.target.remove()
      // })

      // add the shape to the layer
      // layer.add(circle);
    },
    handleMouseMove(e) {
      const startX = this.startx
      const startY = this.starty

      const endX = e.evt.layerX 
      const endY = e.evt.layerY 

      if(this.object && (Math.abs(endX - startX) > 0.02 || Math.abs(endY - startY) > 0.02)) {
        this.drawn = true
        
        // Math.abs(endX - startX) > 0.02 || Math.abs(endY - startY) > 0.02
        if(this.object.style) {

          this.object = new Konva.Arrow({
            points: [startX, startY, endX, endY],
            stroke: 'black',
            fill: 'black',
          });
          this.layer.add(this.object);
          this.layer.batchDraw();
        }
        else {
        this.object.points([startX, startY, endX, endY])
        this.layer.batchDraw();
        }
      }
    },


    handleMouseUp(e) {

      const startX = this.startx
      const startY = this.starty

      const endX = e.evt.layerX 
      const endY = e.evt.layerY 

      if(this.object.style == 'ball') {
        this.object.x = ( startX - this.baseImage.x - (this.offsetX || 0) ) / this.baseImage.width
        this.object.y = ( startY - this.baseImage.y - (this.offsetY || 0) ) / this.baseImage.height
        let ne = this.value.concat(this.object)
        this.$emit('input', ne)
      }
      else {
        let arrow = {
          x: ( startX - this.baseImage.x - (this.offsetX || 0) ) / this.baseImage.width,
          y: ( startY - this.baseImage.y - (this.offsetY || 0) ) / this.baseImage.height,
          end_x: ( endX - this.baseImage.x - (this.offsetX || 0) ) / this.baseImage.width,
          end_y: ( endY - this.baseImage.y - (this.offsetY || 0) ) / this.baseImage.height,
          color: this.colorOverride || this.color,
          style: 'arrow',
          id: this.getNewId().toString(),
        }
        let ne = this.value.concat(arrow)
        this.$emit('input', ne)
      }

      this.object = null;
    },
    getImageMeasures(imageWidth, imageHeight, canvasWidth, canvasHeight) {
      const imageRatio = imageWidth / imageHeight
      let x
      let y
      let width
      let height
      if(imageRatio > 1) {
        // wider than higher
        x = 0
        height = canvasHeight / imageRatio
        y = (canvasHeight - height) / 2
        width = canvasWidth
      } else {
        // higher than wider
        y = 0
        width = canvasWidth * imageRatio
        x = (canvasWidth - width) / 2
        height = canvasHeight
      }
      let data = {
        x, y, height, width
      }

      this.baseImage = data

      return data
    },
    drawPoints() {
      if(!this.circlesVisible) return
      this.value?.forEach(point => {
        var self = this
        if(point.style == 'ball') {
          var circle = new Konva.Circle({
            y: self.baseImage.height * point.y  + self.baseImage.y,
            x: self.baseImage.width * point.x + self.baseImage.x,
            radius: Math.max((self.baseImage.width + this.baseImage.x) / 120, 4),
            fill: point.color,
            stroke: 'black',
            strokeWidth: 0,
            id: point.id.toString(),
            clip_id: point.clip_id
          });

          circle.on('pointerdown', (e) => {
            if(self.color != 'erase') {
              this.$emit('open_clip_by_id', e.target.attrs.clip_id)
              return
            }
            this.removeById(e.target.attrs.id)
          })

          circle.on('mouseover', function (evt) {
            var shape = evt.target;
            document.body.style.cursor = 'pointer';
            shape.scaleX(1.2);
            shape.scaleY(1.2);
            shape.moveToTop();
          });

          circle.on('mouseout', function (evt) {
            var shape = evt.target;
            document.body.style.cursor = 'default';
            shape.scaleX(1);
            shape.scaleY(1);
          });

          this.layer.add(circle);
        }
      })
    },
    drawArrows() {
      if(this.arrowsVisible == false) return
      this.value?.forEach(point => {
        var self = this
        if(point.style == 'arrow') {
          const x = self.baseImage.width * point.x  + self.baseImage.x
          const y = self.baseImage.height * point.y  + self.baseImage.y
          const x2 = self.baseImage.width * point.end_x  + self.baseImage.x
          const y2 = self.baseImage.height * point.end_y  + self.baseImage.y

          var arrow = new Konva.Arrow({
            points: [x, y, x2, y2],
            stroke: 'black',
            fill: 'black',
            id: point.id.toString(),
            clip_id: point.clip_id
          })

          arrow.on('pointerdown', (e) => {
            if(self.color != 'erase') {
              this.$emit('open_clip_by_id', e.target.attrs.clip_id)
              return
            }
            this.removeById(e.target.attrs.id)
          })

          arrow.on('mouseover', function (evt) {
            var shape = evt.target;
            document.body.style.cursor = 'pointer';
            shape.moveToTop();
          });

          arrow.on('mouseout', function () {
            document.body.style.cursor = 'default';
          });

          this.layer.add(arrow);
        }
      })
    },
    // drawAreas() {
    //   // let w = this.baseImage.width
    //   // let h = this.baseImage.height
    //   var triangle = new Konva.Shape({
    //     sceneFunc: function (context, shape) {
    //       context.beginPath();
    //       context.moveTo(0.0468384074941452, 0.0891875766700123);
    //       context.lineTo(24, 143.63);
    //       context.lineTo(64, 45.63);
    //       // context.quadraticCurveTo(150, 100, 260, 170);
    //       context.closePath();

    //       // (!) Konva specific method, it is very important
    //       context.fillStrokeShape(shape);
    //     },
    //     fill: '#00D2FF',
    //     stroke: 'black',
    //     strokeWidth: 1,
    //   });

    //   this.layer.add(triangle);
    // },
    removeById(id) {
      let point = this.layer.find(`#${id}`)[0]
      if(!point) return
      point.destroy()
      this.$emit('input', this.value.filter(p => p.id != id))
    },
    toggleArrows() {
      if(this.circlesVisible && this.arrowsVisible) {
        this.arrowsVisible = !this.arrowsVisible
        var arrows = this.layer.find('Arrow')
        arrows.forEach(arrow => arrow.destroy())
      }
      else if(this.circlesVisible && !this.arrowsVisible) {
        this.arrowsVisible = !this.arrowsVisible
      }
      else {
        return
      }
      this.drawArrows()
    },
    toggleCircles() {
      if(this.circlesVisible && this.arrowsVisible) {
        this.circlesVisible = !this.circlesVisible
        var circles = this.layer.find('Circle')
        circles.forEach(circle => circle.destroy())
      }
      else if(!this.circlesVisible && this.arrowsVisible) {
        this.circlesVisible = !this.circlesVisible
      }
      else {
        return
      }
      this.drawPoints()
    },
  },
  watch: {
    value: {
      handler() {
        // this.layer.find('circle').destroy()
        var circles = this.layer.find('Circle')
        circles.forEach(circle => circle.destroy())
        var arrows = this.layer.find('Arrow')
        arrows.forEach(arrow => arrow.destroy())
        // TODO clear all shapes
        this.drawPoints()
        this.drawArrows()
        // this.drawAreas()
      },
      deep: true
    },
    buttonIndex: {
      handler(newVal) {
        this.color = this.colors[newVal]
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .red {
    background-color: rgb(246, 60, 60);
  }

  .green {
    background-color: rgb(11, 168, 11);
  }

  .ball {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .konvajs-content > canvas {
    margin: 0 auto;
  }

  .togglebtn {
    opacity: 0.4;
  }
  .togglebtn:hover {
    opacity: 1;
  }
</style>