<template>
  <v-dialog
    :value="visible"
    max-width="400"
    @click:outside="$emit('close')"
  >
    <v-card class="pt-10 pb-3">
      <v-card-text>
        <v-text-field
          solo
          v-prevent-keys
          dense
          autofocus
          :value="value"
          @input="$emit('input', $event)"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="$emit('close')"
        >{{$t('cancel')}}</v-btn>
        <v-spacer />
        <v-btn 
          color="primary"
          @click="$emit('rename')"
        >{{$t('rename')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'visible'],
  methods: {
  }
}
</script>