<template>
  <v-card>
    <div class="pt-0" style="text-align: center;">
      <v-text-field class="pa-3 ml-5" style="width: 500px" dense @change="$emit('input', {title: $event, text: value.text})" :value="value.title"></v-text-field>
    </div>
    <div class="px-7 pb-5">
      <VueEditor
        @input="$emit('input', { ...value, text: $event })"
        id="editorcontainer"
        :editorOptions="options"
        :value="value.text"
      ></VueEditor>
    </div>
    <v-spacer></v-spacer>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn @click="$emit('close')">
        {{$t('cancel')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="saveTextFile()">
        {{$t('save')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from 'vuex'
export default {
  props: ['value', 'editing'],
  components: { VueEditor },
  data: () => ({
    editingTitle: false,
    options: {
      scrollingContainer: "#editorcontainer",
      readOnly: false,
      formats: [
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula'
        // 'image'
        // 'video'
      ],
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{ 'align': [] }],
          ['blockquote', 'code-block'],

          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent


          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          // [{ 'font': [] }],
        ]
      },
    }
  }),
  methods: {
    ...mapActions('text_file', [ 'saveFile', 'updateFile' ]),
    ...mapActions('folder', [ 'addFolder' ]),
    ...mapActions('noti', [ 'error', 'success' ]),
    async saveTextFile() {
      if(this.editing) {
        this.editFile()
        return
      }

      let file;
      try {
        file = await this.saveFile(this.value)
      } catch(e) {
        this.error(e)
        return
      }

      try {
        this.addFolder({
          name: this.value.title,
          type: 'text_file',
          text_file_id: file.id
        })
      } catch(e) {
        this.error(e)
        return
      }

      this.success(this.$t('text_file.file_created'))
      this.$emit('input', { title: 'No title', text: '' })
      this.$emit('close')
    },
    async editFile() {
      let file;
      try {
        file = await this.updateFile({...this.value})
      } catch(e) {
        this.error(e)
      }

      this.success(this.$t('text_file.file_updated'))
      this.$emit('update', file)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
  .ql-editor{
    max-height: calc(100vh - 110px);
    overflow: auto;
  }
</style>