<template>
  <v-dialog
    max-width="800"
    v-model="visible"
    @click:outside="$emit('closed')"
  >
    <v-card class="py-10">
      <v-card-title>
        {{ text }}
      </v-card-title>
      <v-card-text
        style="overflow-y: scroll"
      >
        <v-chip
          :color="chosen == 'root' ? 'primary' : ''"
          @click="chosen = 'root'"
        >
          <v-icon>mdi-folder-outline</v-icon>
          {{$t('library.library')}}
        </v-chip>
        <FolderTree
          class="ml-4"
          v-for="folder in rootLevel"
          :key="folder.id"
          :folder="folder"
          @input="chosen = $event"
          :chosen="chosen"
          :disabledParentId="disabledParentId"
        ></FolderTree>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="chosen = null; setChosen(null)">
          {{$t('clear')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('closed')"
        >
          <v-icon>
            mdi-minus
          </v-icon>
        </v-btn>
        <v-btn
          @click="setChosen(chosen)"
          :disabled="!chosen"
          color="primary"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FolderTree from './FolderTree'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { FolderTree },
  data: () => ({
    chosen: null
  }),
  mounted() {
    this.getFolders()
      .then()
      .catch(e => this.error(e))
  },
  props: ['value', 'visible', 'text', 'disabledParentId'],
  methods: {
    ...mapActions('folder', [
      'getFolders'
    ]),
    ...mapActions('noti', [
      'error'
    ]),
    setChosen(e) {
      this.$emit('chosen', e)
    }
  },
  computed: {
    ...mapGetters('folder', [
      'folders',
      'structuredFolders',
      'rootLevel'
    ])
  }
}
</script>