<template>
  <v-dialog 
    v-if="map" 
    v-model="map"
    v-click-outside="clickedOutside"
  >
    <open-map
      v-if="map"
      :map="map"
      @close="$emit('close')"
    ></open-map>
  </v-dialog>
</template>

<script>
import OpenMap from './OpenMap.vue'
import { mapActions } from 'vuex'
export default {
  props: ['map_id'],
  data: () => ({
    map: null
  }),
  components: {
    OpenMap
  },
  created() {
    // Fetch map data
    console.log('get map')
    this.getMap({id: this.map_id})
      .then(e => {
        this.map = e
      })
  },
  methods: {
    ...mapActions('map', [
      'getMap'
    ]),
    clickedOutside() {
      this.$emit('close')
    }
  }
}
</script>OpenMap