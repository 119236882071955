<template>
  <div class="my-1">
    <v-chip
      :disabled="isDisabled"
      @click="$emit('input', folder)"
      :color="chosen && chosen.id == folder.id ? 'primary' : ''"
    >
      <v-icon class="mr-3">mdi-folder-outline</v-icon>
      {{folder.name}}
    </v-chip>
    <div v-if="children(folder.id) && children(folder.id).length">
      <FolderTree
        @input="$emit('input', $event)"
        class="ml-6"
        v-for="child in children(folder.id)"
        :key="child.id"
        :folder="child"
        :chosen="chosen"
        :disabledParentId="isDisabled ? child.id : disabledParentId"
      ></FolderTree>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FolderTree',
  props: ['folder', 'opened', 'chosen', 'disabledParentId'],
  computed: {
    ...mapGetters('folder', [
      'children'
    ]),
    isDisabled() {
      return this.disabledParentId == this.folder.id
    }
  }
}
</script>