<template>
  <v-dialog v-model="show" @click:outside="$emit('close')" max-width="600">
    <v-card>
      <v-card-title>
        {{ $t("clips.choose_clips") }}
      </v-card-title>
      <v-text-field
        class="ml-3 mr-7"
        label="Search clips"
        prepend-icon="mdi-magnify"
        solo
        clearable
        clear-icon="mdi-close-circle"
        dense
        v-model="searchInput"
      >
      </v-text-field>
      <v-layout align-center justify-center>
        <v-progress-circular indeterminate color="primary" v-if="isLoading" class="mb-7"></v-progress-circular>
      </v-layout>
      <div v-if="!hasClips && !isLoading">
        <v-card-text style="color: grey; font-size: 15px" class="pl-6">
          No clips yet. Let's get you started by adding videos and clips!
        </v-card-text>
        <router-link to="/videos">
          <v-btn color="primary" class="mx-5 mb-3"> Own videos </v-btn>
        </router-link>
      </div>
      <v-card-text class="pb-6 pt-0" v-else-if="!isLoading">
        <div v-for="video in this.filteredVideos" :key="video.id + 'clipadder'" class="py-3">
          <div style="color: grey; font-size: 15px" class="pl-5 pb-2">
            {{ video.title }}
          </div>
          <div class="px-2">
            <v-chip
              v-for="clip in (expandedVideos.includes(video.id) ? video.clips : video.clips.slice(0,3))"
              :key="clip.id + 'clipid'"
              style="cursor: pointer;"
              :color="isChosen(clip.id) ? 'primary' : ''"
              :disabled="currentClips.map((e) => e.clip_id).includes(clip.id)"
              @click="handleClick(clip)"
            >
              <v-icon class="mr-5">mdi-format-horizontal-align-center</v-icon>
              {{ clip.title || "Untitled" }}
            </v-chip>
            <v-btn v-if="video.clips.length > 3"
            @click="toggleClips(video.id)" 
            class="ml-2 my-2 pr-0 pl-2 showClips" 
            rounded
            x-small 
            outlined
            >
              Show {{ expandedVideos.includes(video.id) ? "less" : "all" }} 
              <v-icon v-if="!expandedVideos.includes(video.id)">mdi-menu-down-outline</v-icon>
              <v-icon v-else>mdi-menu-up-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="$emit('close')">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addClips()">{{
          $t("clips.add_clips")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["show", "currentClips"],
  shortcuts: {
    enter: function () {
      if (this.show) this.addClips();
    },
    escape: function () {
      if (this.show) this.$emit("close");
    },
  },
  created() {
    this.isLoading = true;
      this.getVideoClips()
        .then((e) => {
          this.videos = e;
        })
        .catch((e) => {
          this.error(e)
        })
        .finally(() => {
            this.isLoading = false;
        });
  },
  data: () => ({
    videos: [],
    chosenClips: [],
    searchInput: '',
    expandedVideos: [],
    isLoading: false,
  }),
  methods: {
    toggleClips(id) {
      const index = this.expandedVideos.indexOf(id);
      if (index !== -1) this.expandedVideos.splice(index, 1);
      else this.expandedVideos.push(id);
    },
    isChosen(id) {
      return this.chosenClips.map((e) => e.id).includes(id);
    },
    handleClick(clip) {
      if (this.isChosen(clip.id)) {
        this.chosenClips = this.chosenClips.filter((e) => e.id != clip.id);
      } else {
        this.chosenClips = [...this.chosenClips, clip];
      }
    },
    addClips() {
      this.$emit(
        "add",
        this.chosenClips.map((c) => {
          return { id: c.id, title: c.title };
        })
      );
      this.chosenClips = [];
    },
    ...mapActions("folder", ["getVideoClips"]),
    ...mapActions("noti", ["error"]),
  },
  computed: {
  filteredVideos() {
    if (!this.searchInput) {
      return this.videos;
    }
    return this.videos.reduce((filtered, video) => {
      const filteredClips = video.clips.filter(clip => {
        return clip.title.toLowerCase().includes(this.searchInput.toLowerCase());
      });
      if (filteredClips.length > 0) {
        const videoCopy = {...video, clips: filteredClips};
        filtered.push(videoCopy);
      }
      return filtered;
    }, []);
  },
    hasVideos() {
      return this.videos.length !== 0;
    },
    hasClips() {
      const clips = this.videos.map((v) => v.clips).flat();

      return clips.length !== 0;
    },
  },
};
</script>

<style scoped>
  .showClips {
    text-transform: unset !important;
    border-color: lightgrey;
      }
</style>