<template>
  <v-sheet class="py-5" style="position: relative;">
    <v-btn @click="$emit('close')" fab style="top: 10px; right: 10px; position: absolute; z-index: 100;">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row no-gutters>
      <v-col class="pa-0" cols="12" :md="map && map.description ? '6' : '12'">
        <v-card-title class="ml-10">
          {{ map.title }}
        </v-card-title>
        <Map
          :maxHeight="windowHeight * 0.6"
          :maxWidth="windowWidth * 0.8"
          :hideButtons="true"
          :disableClicks="true"
          :value="points"
          :url="map.url"
        ></Map>
      </v-col>
      <v-col v-if="map && map.description" class="pa-0" cols="12" :md="map.description ? '6' : '0'">
        <v-card class="ma-5">
          <v-card-text>
            {{map.description}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import Map from '@/components/maps/Map'
import a from '@/utils/axios/axios'

export default {
  props: ['map'],
  components: { Map },
  data: () => ({
    points: []
  }),
  created() {
    a.get(`/maps/${this.map.id}/points`)
      .then(e => this.points = e.data)
      .catch(e => this.error(e))
  },
  computed: {
    windowHeight() {
      return window.innerHeight
    },
    windowWidth() {
      return window.innerWidth
    }
  }
}
</script>